/* Pretendard */
/* black */
@font-face {
	font-family: "pretendard-black-otf";
	src: url("../../public/font/Pretendard-Black.otf") format("otf");
}
@font-face {
	font-family: "pretendard-black-ttf";
	src: url("../../public/font/Pretendard-Black.ttf") format("ttf");
}
@font-face {
	font-family: "pretendard-black-woff";
	src: url("../../public/font/Pretendard-Black.woff") format("woff");
}
@font-face {
	font-family: "pretendard-black-woff2";
	src: url("../../public/font/Pretendard-Black.woff2") format("woff2");
}

/* bold */
/* @font-face {
    font-family: 'pretendard-bold-otf';
    src: url('../../public/font/Pretendard-Bold.otf') format('otf');
  }
  @font-face {
    font-family: 'pretendard-bold-ttf';
    src: url('../../public/font/Pretendard-Bold.ttf') format('ttf');
  }
  @font-face {
    font-family: 'pretendard-bold-woff';
    src: url('../../public/font/Pretendard-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'pretendard-bold-woff2';
    src: url('../../public/font/Pretendard-Bold.woff2') format('woff2');
  } */

/* semibold */
/* @font-face {
    font-family: 'pretendard-semibold-otf';
    src: url('../../public/font/Pretendard-SemiBold.otf') format('otf');
  }
  @font-face {
    font-family: 'pretendard-semibold-ttf';
    src: url('../../public/font/Pretendard-SemiBold.ttf') format('ttf');
  }
  @font-face {
    font-family: 'pretendard-semibold-woff';
    src: url('../../public/font/Pretendard-SemiBold.woff') format('woff');
  }
  @font-face {
    font-family: 'pretendard-semibold-woff2';
    src: url('../../public/font/Pretendard-SemiBold.woff2') format('woff2');
  } */

/* medium */
@font-face {
	font-family: "pretendard-medium-otf";
	src: url("../../public/font/Pretendard-Medium.otf") format("otf");
}
@font-face {
	font-family: "pretendard-medium-ttf";
	src: url("../../public/font/Pretendard-Medium.ttf") format("ttf");
}
@font-face {
	font-family: "pretendard-medium-woff";
	src: url("../../public/font/Pretendard-Medium.woff") format("woff");
}
@font-face {
	font-family: "pretendard-medium-woff2";
	src: url("../../public/font/Pretendard-Medium.woff2") format("woff2");
}

/* regular */
@font-face {
	font-family: "pretendard-regular-otf";
	src: url("../../public/font/Pretendard-Regular.otf") format("otf");
}
@font-face {
	font-family: "pretendard-regular-ttf";
	src: url("../../public/font/Pretendard-Regular.ttf") format("ttf");
}
@font-face {
	font-family: "pretendard-regular-woff";
	src: url("../../public/font/Pretendard-Regular.woff") format("woff");
}
@font-face {
	font-family: "pretendard-regular-woff2";
	src: url("../../public/font/Pretendard-Regular.woff2") format("woff2");
}
/* Pretendard */

:root {
	--pretendard-black: "pretendard-black-otf", "pretendard-black-ttf",
		"pretendard-black-woff", "pretendard-black-woff2";
	/* --pretendard-bold: 'pretendard-bold-otf', 'pretendard-bold-ttf', 'pretendard-bold-woff',
      'pretendard-bold-woff2'; */
	--pretendard-semibold: "pretendard-semibold-otf", "pretendard-semibold-ttf",
		"pretendard-semibold-woff", "pretendard-semibold-woff2";
	--pretendard-medium: "pretendard-medium-otf", "pretendard-medium-ttf",
		"pretendard-medium-woff", "pretendard-medium-woff2";
	--pretendard-regular: "pretendard-regular-otf", "pretendard-regular-ttf",
		"pretendard-regular-woff", "pretendard-regular-woff2";
}
